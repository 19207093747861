import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Spinner } from 'app/components/Common/Spinner'
import React, { memo } from 'react'

import { Icon } from './Icon'

export interface Props {
  description?: string
  title?: string
}

export const QuotationForm = memo(function QuotationForm({
  description,
  title,
}: Props) {
  return (
    <Container>
      <Icon />

      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}

      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}

      <Wrapper>
        <Spinner />

        <ConvertoForm id="converto" />
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 14rem auto 12.5rem;
  padding: 0 2.25rem;
  position: relative;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 10rem auto 7.5rem;
    padding: 0 1.5rem;
  }
`

const Title = styled.h1`
  max-width: 53.125rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.8125rem;
  font-weight: 300;
  line-height: 3.4375rem;
  margin: auto;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 2.8125rem;
  }
`

const Description = styled.div`
  max-width: 36.875rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 200;
  line-height: 2.125rem;
  margin: 1.875rem auto 0;

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 1.125rem;
    line-height: 2.125rem;
  }
`

const Wrapper = styled.div`
  min-height: 41.875rem;
  position: relative;
`

const ConvertoForm = styled.div`
  max-width: 49.25rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight5};
  margin: 3.75rem auto 0;
  position: relative;
  z-index: 2;

  @media (max-width: 1199px) {
    max-width: none;
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
`
